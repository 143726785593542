import "./App.css";
import HomePage from "./components/homepage/HomePage";

function App() {
  return <div className="App">
    <HomePage />
  </div>;
}

export default App;
